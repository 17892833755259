



import React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout"
// import Week from "../components/week"
// import Schedule from "../components/schedule"
import MiniLayout from "../../components/minimalLayout"


const Time = ({data}) => {

  return (
    <Layout>
      <MiniLayout>

        <h2>time (mini project #2)</h2>
        <p>build a website inspired by the concept of time. Make use of the techniques reviewed in Lab #2 (the window object, popup windows, setTimeout and setInterval) and explore them further (e.g. what other methods does the window object have?).</p>
        <p>This website can treat a subject or idea you are interested in, but can also be a pure exploration of form and composition, and movement.</p>

        <p>Focus on an intentional design language and deliberate usage of techniques.</p>
        <p>Pay attention to how your website looks on different browser and screen sizes.</p>

        <br></br>
        <p>when you are done:</p>
        <p>1. push your new project to your GitHub repositoy</p>
        <p>2. find it on your GitHub Pages (I mean, the rendered version)</p>
        <p>3. and link the project to your Room</p>
      </MiniLayout>
    </Layout>
  )
}



export default Time
